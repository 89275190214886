/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from "react";
import "./index.css";
import AbsoluteChart from "./AbsoluteChart";
import DynamicChart from "./DynamicChart";
import { socket } from "../config/socket";
import explode from "../../assets/animationGif/crash.png";
import { getBalance } from "../config/axios";
import toast from "react-hot-toast";
import { unit } from "../../utils";

let debounce = 0;
const Chart = ({
  betValue,
  setBalance,
  setIsPlaying,
  isPlaying,
  cashoutValue,
  currentOdd,
  setCurrentOdd,
  setIsCashedOut,
  isMuted,
  winMusic1,
  winMusic2,
  winMusic3,
  gameStartSound,
}) => {
  const [gameState, setGameState] = useState({
    serverTime: Date.now(),
    isCrashed: false,
    isStarted: false,
  });
  const [preparingTimer, setPreparingTimer] = useState(0);
  const [currGameId, setCurrGameId] = useState(null);
  const [timestamp, setTimestamp] = useState(0);

  let isOnceStarted = false;

  const delay = function (e, t) {
    let a = `${timestamp + e}s`;
    if (t) {
      a = `${timestamp + e}s, ${timestamp + t}s`;
    }
    return {
      animationDelay: a,
    };
  };

  useEffect(() => {
    const { isCrashed, isStarted, launchTime } = gameState;

    if (isStarted && !isCrashed && launchTime) {
      setTimestamp((launchTime - Date.now()) / 1e3);
    } else if (isCrashed) {
      if (isPlaying) {
        toast.error(`You lost ${Number(betValue)?.toFixed(2)} ${unit} `);
      }
      setTimeout(() => {
        getBalance().then((res) => {
          setBalance(res?.data);
        });
      }, 2000);
    }
  }, [gameState]);

  useEffect(() => {
    socket.on("gamestate", (data) => {
      setGameState(data);

      if (data.isStarted) {
        setPreparingTimer(0);
      }
    });

    socket.on("currentOdd", (data) => {
      if (document.visibilityState === "hidden" && debounce < 20) {
        debounce++;
      } else if (
        (document.visibilityState === "hidden" && debounce === 20) ||
        document.visibilityState === "visible"
      ) {
        setCurrentOdd(data.currentOdd.toFixed(2));
        debounce = 0;
      }
      if (!isOnceStarted) {
        setGameState((prev) => ({ ...prev, isStarted: true }));
        isOnceStarted = true;
      }

      setCurrentOdd(data.currentOdd.toFixed(2));
    });

    socket.on("preparing", (data) => {
      setPreparingTimer(data.timer);
      setCurrGameId(data.game_id);
      setCurrentOdd("");
      setGameState((prev) => ({ ...prev, launchTime: 0 }));

      setIsCashedOut(false);
    });
  }, []);

  useEffect(() => {
    if (currGameId) {
      localStorage.setItem("gameId", currGameId);
    }
  }, [currGameId]);

  useEffect(() => {
    const winValue = betValue * cashoutValue - betValue;
    if (
      Number(cashoutValue) !== 0 &&
      currentOdd > Number(cashoutValue) &&
      isPlaying
    ) {
      toast.success(`You won ${winValue.toFixed(2)} ${unit} `);
      setIsPlaying(false);

      gameStartSound.fade(0, 0.6, 1500);

      const winAudios = [winMusic1, winMusic2, winMusic3];
      const randomIndex = Math.floor(Math.random() * winAudios.length);

      !isMuted && winAudios[randomIndex]();
    }
  }, [currentOdd]);

  const memoizedGameState = useMemo(() => gameState, [gameState]);

  let centerAligned = window.innerWidth > 600;

  return (
    <>
      <div className="game-holder">
        <div
          className={`game-inner-holder ${
            memoizedGameState.isStarted && memoizedGameState.isCrashed
              ? "booming"
              : ""
          } ${memoizedGameState.isStarted ? "startAnimations" : ""}`}
        >
          <div className={`${centerAligned && "chart-wrapper"}`}>
            <DynamicChart
              delay={delay}
              started={memoizedGameState.isStarted}
              crashed={memoizedGameState.isCrashed}
              launchTime={memoizedGameState.launchTime || Date.now()}
            />

            <AbsoluteChart
              animate={memoizedGameState.isStarted}
              launchTime={memoizedGameState.launchTime || Date.now()}
            />
          </div>
          <div
            className={`current-odd-holder ${
              memoizedGameState.isStarted || memoizedGameState.isCrashed
                ? "active"
                : ""
            }`}
          >
            <div className="current-odd">
              {currentOdd ? (
                <>
                  <div>{currentOdd}</div>
                  <span>x</span>
                </>
              ) : (
                ""
              )}
            </div>
          </div>
          {memoizedGameState.isCrashed && (
            <div className="crash-explode">
              <img src={explode} alt="" />
              <h4>REKT</h4>
            </div>
          )}
          <div
            className={`information-layer absolute-splash ${
              preparingTimer ? "show startWaitingAnimations" : ""
            }`}
          >
            <ul className="waiting-circle">
              <li className="absolute-splash">
                <svg viewBox="0 0 100 100">
                  <defs>
                    <linearGradient
                      x1="0%"
                      y1="0%"
                      x2="100%"
                      y2="0%"
                      id="wc-gradient-0"
                    >
                      <stop offset="0%" stopColor="#fd4a95"></stop>
                      <stop offset="100%" stopColor="#ff9954"></stop>
                    </linearGradient>
                  </defs>
                  <path d="M 50,50 m 0,-47a 47,47 0 1 1 0,94a 47,47 0 1 1 0,-94"></path>
                  <path
                    d="M 50,50 m 0,-47a 47,47 0 1 1 0,94a 47,47 0 1 1 0,-94"
                    stroke="url('#wc-gradient-0')"
                    style={{ animationDuration: "4s", animationDelay: "-1s" }}
                  ></path>
                </svg>
                <div
                  className="wc-arrow absolute-splash"
                  style={{ animationDuration: "4s", animationDelay: "-1s" }}
                >
                  <div
                    className="icon icon-arrow-drop"
                    style={{ animationDuration: "4s", animationDelay: "-1s" }}
                  ></div>
                </div>
              </li>
              <li className="absolute-splash">
                <svg viewBox="0 0 100 100">
                  <defs>
                    <linearGradient
                      x1="0%"
                      y1="0%"
                      x2="100%"
                      y2="0%"
                      id="wc-gradient-1"
                    >
                      <stop offset="0%" stopColor="#ff5cee"></stop>
                      <stop offset="100%" stopColor="#494be1"></stop>
                    </linearGradient>
                  </defs>
                  <path d="M 50,50 m 0,-47a 47,47 0 1 1 0,94a 47,47 0 1 1 0,-94"></path>
                  <path
                    d="M 50,50 m 0,-47a 47,47 0 1 1 0,94a 47,47 0 1 1 0,-94"
                    stroke="url('#wc-gradient-1')"
                    style={{ animationDuration: "5s", animationDelay: "0s" }}
                  ></path>
                </svg>
                <div
                  className="wc-arrow absolute-splash"
                  style={{ animationDuration: "5s", animationDelay: "0s" }}
                >
                  <div
                    className="icon icon-arrow-drop"
                    style={{ animationDuration: "5s", animationDelay: "0s" }}
                  ></div>
                </div>
              </li>
              <li className="absolute-splash">
                <svg viewBox="0 0 100 100">
                  <defs>
                    <linearGradient
                      x1="0%"
                      y1="0%"
                      x2="100%"
                      y2="0%"
                      id="wc-gradient-2"
                    >
                      <stop offset="0%" stopColor="#8356ee"></stop>
                      <stop offset="100%" stopColor="#38a0ee"></stop>
                    </linearGradient>
                  </defs>
                  <path d="M 50,50 m 0,-47a 47,47 0 1 1 0,94a 47,47 0 1 1 0,-94"></path>
                  <path
                    d="M 50,50 m 0,-47a 47,47 0 1 1 0,94a 47,47 0 1 1 0,-94"
                    stroke="url('#wc-gradient-2')"
                    style={{ animationDuration: "6s", animationDelay: "1s" }}
                  ></path>
                </svg>
                <div
                  className="wc-arrow absolute-splash"
                  style={{ animationDuration: "6s", animationDelay: "1s" }}
                >
                  <div
                    className="icon icon-arrow-drop"
                    style={{ animationDuration: "6s", animationDelay: "1s" }}
                  ></div>
                </div>
              </li>
              <li className="absolute-splash">{preparingTimer}</li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Chart;
