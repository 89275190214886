import React from "react";
import toast from "react-hot-toast";
import { Button, Form } from "react-bootstrap";
import arrowup from "../../assets/arrow-up.png";
import arrowdown from "../../assets/arrow-down.png";

const ManualField = ({
  isPlaying,
  setIsPlaying,
  activeBox,
  betValue,
  handleBet,
  cashoutValue,
  setCashoutValue,
  gameStarted,
  handleCashOut,
  gameOver,
  handleBetValue,
  handleOnBlur,
  testMode,
  isLoading,
  balance,
  minBetValue,
  maxBetValue,
  minCashoutValue,
  maxCashoutValue,
  incrementDecrementBy,
}) => {
  const handleChangeCashout = (value) => {
    if (value >= 0) {
      if (value > maxCashoutValue) {
        toast.error(`Maximum Auto Cashout allowed is ${maxCashoutValue} x`);
        setCashoutValue(maxCashoutValue);
      } else {
        setCashoutValue(value);
      }
    }
  };

  const handleIncDecCashout = (value, type) => {
    if (type === "minus" && cashoutValue <= 1.5) {
      setCashoutValue((1).toFixed(2));
    }
    if (type === "plus" && cashoutValue === 0) {
      setCashoutValue((1).toFixed(2));
    } else if (value >= 1 && value <= 100) {
      setCashoutValue(Number(value).toFixed(2));
    }
  };

  const handleCashoutOnBlur = (e) => {
    const { value } = e.target;

    if (Number(value) === 0) {
      setCashoutValue(0);
      return;
    }

    if (!value || Number(value) < 0) {
      toast.error(`Minimum Auto Cashout allowed is ${minCashoutValue} x`);
      setCashoutValue(Number(minCashoutValue).toFixed(2));
    }
  };

  const hideNumPad = (e) => {
    e.preventDefault();
    e.target.blur();
  };

  const handleKeyUp = (event, ...extraKeys) => {
    if (event.key === "Enter" || event.keyCode === 13) {
      hideNumPad(event);
    }

    let disallowedChars = ["-", "+", "e", "E"];
    if (extraKeys.length > 0) {
      disallowedChars = [...disallowedChars, ...extraKeys];
    }
    if (disallowedChars?.includes(event.key)) {
      event.preventDefault();
    }
  };

  const changeBtnText = () => {
    if (gameStarted && !gameOver) {
      if (testMode) {
        return <Button onClick={handleCashOut}>Cashout</Button>;
      }
      return (
        <Button onClick={handleCashOut} disabled={!isPlaying}>
          <> {!isPlaying ? "Play" : "Cashout"}</>
        </Button>
      );
    }

    if (gameStarted) {
      setIsPlaying(false);
    }
    return (
      <Button onClick={handleBet} disabled={isLoading}>
        {isLoading ? "Waiting" : "Play"}
      </Button>
    );
  };

  return (
    <div className={`rekt box-section ${activeBox ? "disable-bet" : ""}`}>
      <div className="manual-tab">
        <Form>
          <div className="bet-btn mb-3">{changeBtnText()}</div>
          <div className="rekt-bet-box">
            <Form.Group className="form-group-manual mb-0">
              <div className="form-bet-container">
                <div className="bet-input pdd-200">
                  <Form.Label>Bet Amount</Form.Label>

                  <Form.Control
                    className="form-bet-input"
                    type="number"
                    placeholder="0.00"
                    value={betValue}
                    onChange={(e) => handleBetValue(e.target.value, true)}
                    onBlur={handleOnBlur}
                    onKeyUp={handleKeyUp}
                    min={minBetValue}
                    max={balance || maxBetValue}
                  />
                </div>
                <div className="bet-input-btn">
                  <Button
                    onClick={() =>
                      handleBetValue(
                        Number(betValue).toFixed(2) / 2,
                        false,
                        balance?.amount?.toFixed(2)
                      )
                    }
                  >
                    1/2
                  </Button>
                  <Button
                    className="bet-2x-btn"
                    onClick={() =>
                      handleBetValue(
                        Number(betValue).toFixed(2) * 2,
                        false,
                        balance?.amount?.toFixed(2)
                      )
                    }
                  >
                    2x
                  </Button>
                  <Button
                    className="bet-max-btn"
                    onClick={() =>
                      handleBetValue(
                        Number(balance?.amount) < maxBetValue
                          ? balance?.amount?.toFixed(2)
                          : maxBetValue,
                        false,
                        balance?.amount?.toFixed(2)
                      )
                    }
                  >
                    Max
                  </Button>
                </div>
              </div>
            </Form.Group>

            <Form.Group className="form-group-manual">
              <div className="form-bet-container">
                <div className="bet-input pdd-200">
                  <Form.Label>Auto Cashout At</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="1"
                    className="form-cashout-input"
                    value={cashoutValue}
                    onChange={(e) => handleChangeCashout(e.target.value)}
                    onKeyUp={handleKeyUp}
                    onBlur={handleCashoutOnBlur}
                    min={0}
                  />
                </div>
                <div className="action-bet">
                  <Button
                    className="cashout-minus-btn"
                    onClick={() =>
                      handleIncDecCashout(
                        +cashoutValue - incrementDecrementBy,
                        "minus"
                      )
                    }
                  >
                    <img src={arrowdown} alt="arrow-down" />
                  </Button>
                  <Button
                    className="cashout-plus-btn"
                    onClick={() =>
                      handleIncDecCashout(
                        +cashoutValue + incrementDecrementBy,
                        "plus"
                      )
                    }
                  >
                    <img src={arrowup} alt="arrow-up" />
                  </Button>
                </div>
              </div>
            </Form.Group>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default ManualField;
