import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import CrashGame from "./pages/crash/CrashGame";
import "bootstrap/dist/css/bootstrap.min.css";

const tele = window.Telegram.WebApp;

function App() {
  useEffect(() => {
    tele.ready();
  }, []);

  useEffect(() => {
    const clearLocalStorage = () => {
      localStorage.clear();
    };

    const handleTabClose = () => {
      clearLocalStorage();
    };

    window.addEventListener("beforeunload", handleTabClose);

    return () => {
      window.removeEventListener("beforeunload", handleTabClose);
    };
  }, []);

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<CrashGame />} />
        <Route path="/:user/:id" element={<CrashGame />} />
      </Routes>
    </div>
  );
}

export default App;
