import { Header } from "../header/header";
import "./layout.css";
import  { Toaster } from 'react-hot-toast';

export const Layout = ({ children }) => {


  return (
    <> 
      <div className="crash-layout">
        <div className="crash-content">{children}</div>
      </div>
      <Toaster/>
    </>
  );
};
