export const shuffle = (array) => {
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex !== 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
};

export const MinesMultiplier = (diamond, bomb) => {
  let restDiamond = 25 - diamond;
  const winProb = (restDiamond - bomb) / 25;
  return 1 / winProb;
};

export const convertToTwoDecimalPlaces = (input) => {
  // Check if the input is a valid number
  if (!/^-?\d+(\.\d+)?$/.test(input)) {
    return "Invalid number";
  }

  const num = parseFloat(input);

  // Check if the number is an integer
  if (Number.isInteger(num)) {
    return num.toString(); // Return as an integer
  } else {
    return num.toFixed(2); // Return with two decimal places
  }
};

export const randomNumber = (min, max) => {
  return Math.random() * (max - min) + min;
};

export function biasedRandom(min, max, bias) {
  let rand = Math.random();
  rand = Math.pow(rand, bias);
  return Math.floor(min + rand * (max - min));
}

// export const byteGenerator = ({ serverSeed, clientSeed, nonce, cursor }) => {
//   // Setup curser variables
//   let currentRound = Math.floor(cursor / 32);
//   let currentRoundCursor = cursor;
//   currentRoundCursor -= currentRound * 32;

//   // Generate outputs until cursor requirement fullfilled
//   while (true) {
//     // HMAC function used to output provided inputs into bytes
//     const hmac = createHmac('sha256', serverSeed);
//     hmac.update(`${clientSeed}:${nonce}:${currentRound}`);
//     const buffer = hmac.digest();

//     // Update curser for next iteration of loop
//     while (currentRoundCursor < 32) {
//       yield Number(buffer[currentRoundCursor]);
//       currentRoundCursor += 1;
//     }
//     currentRoundCursor = 0;
//     currentRound += 1;
//   }

// }

// export const crashPoint = () => {
//   const gameHash = hashChain.pop()

//   const hmac = createHmac('sha256', gameHash);

//   // blockHash is the hash of bitcoin block 584,500

//   hmac.update(blockHash);

//   const hex = hmac.digest('hex').substr(0, 8);
//   const int = parseInt(hex, 16);

//   // 0.01 will result in 1% house edge with a lowest crashpoint of 1

//   const crashpoint = Math.max(1, (2 ** 32 / (int + 1)) * (1 - 0.01))
// }

export const isMobile = (window.mobileAndTabletCheck = function () {
  let check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
});

export const unit = "$REKT";

export const getDateTimeNumerical = (timestamp) => {
  const date = new Date(timestamp);

  // Months array to convert numerical month to its abbreviated form
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // Get the day, month, year, hours, and minutes
  const day = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();

  // Pad the minutes and seconds with leading zero if necessary
  const paddedMinutes = (minutes < 10 ? "0" : "") + minutes;
  const paddedSeconds = (seconds < 10 ? "0" : "") + seconds;

  // Construct the formatted date string
  const formattedDate = `${day} ${month} ${year}`;
  const formattedTime = `${hours}:${paddedMinutes}:${paddedSeconds}`;

  return { date: formattedDate, time: formattedTime };
};

export const getDateTime = (timestamp) => {
  const date = new Date(timestamp);

  // Get the day, month, year, hours, minutes, and seconds
  const day = date.getDate();
  const month = date.getMonth() + 1; // Months are zero-indexed, so add 1
  const year = date.getFullYear() % 100;
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();

  // Pad the single-digit values with leading zero if necessary
  const paddedDay = (day < 10 ? "0" : "") + day;
  const paddedMonth = (month < 10 ? "0" : "") + month;
  const paddedHours = (hours < 10 ? "0" : "") + hours;
  const paddedMinutes = (minutes < 10 ? "0" : "") + minutes;
  const paddedSeconds = (seconds < 10 ? "0" : "") + seconds;

  // Construct the formatted date and time strings in numerical format
  const formattedDate = `${paddedDay}/${paddedMonth}/${year}`;
  const formattedTime = `${paddedHours}:${paddedMinutes}:${paddedSeconds}`;

  return { date: formattedDate, time: formattedTime };
};
