/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import ManualField from "../../pages/crash/manualField";
import "./crash.css";
import { unit } from "../../utils/index";
import { Layout } from "../layout/layout";
import BetContent from "../../components/Crash/BetContent";
import explode from "../../assets/animationGif/crash.png";
import Chart from "../../components/Chart";
import queryString from "query-string";
import { MdMusicNote, MdMusicOff } from "react-icons/md";

import { RektGameSplash } from "../splash";
import {
  bid,
  cashout,
  getBalance,
  getGameConfig,
  getUserBetHistory,
  login,
} from "../../components/config/axios";

import useSound from "use-sound";

import { socket } from "../../components/config/socket";
import toast from "react-hot-toast";
import { Header } from "../header/header";
import BetHistory from "../../components/BetHistory";

import StartAudio from "../../assets/audio/main.mp3";
import WinAudio1 from "../../assets/audio/win1.mp3";
import WinAudio2 from "../../assets/audio/win2.mp3";
import WinAudio3 from "../../assets/audio/win3.mp3";
import LoseAudio from "../../assets/audio/lose.mp3";

const CrashGame = () => {
  const [gameStartMusic, { stop: stopGameStartMusic, sound: gameStartSound }] =
    useSound(StartAudio, {
      volume: 0.6,
    });

  const [winMusic1, { stop: stopWinMusic1 }] = useSound(WinAudio1);

  const [winMusic2, { stop: stopWinMusic2 }] = useSound(WinAudio2);

  const [winMusic3, { stop: stopWinMusic3 }] = useSound(WinAudio3);

  const [loseGameMusic, { stop: stopLoseGameMusic }] = useSound(LoseAudio, {
    volume: 0.6,
  });

  const [isMuted, setIsMuted] = useState(true);

  const [gameStarted, setGameStarted] = useState(false);
  const [gameOver, setGameOver] = useState(false);
  const [userBidData, setUserBidData] = useState([]);
  const [userData, setUserData] = useState(null);
  const [betValue, setBetValue] = useState(0);
  const [cashoutValue, setCashoutValue] = useState(0);

  const parsed = queryString.parse(window.location.search);

  const { name: user, identifier: id } = parsed;

  const [balance, setBalance] = useState(
    JSON.parse(localStorage.getItem("balance"))
  );
  const [isExploding, setIsExploding] = React.useState(false);
  const [isPlaying, setIsPlaying] = React.useState(false);
  const [currentOdd, setCurrentOdd] = useState("");
  const [testMode, setTestMode] = useState(
    localStorage.getItem("testmode") === "true"
  );
  const [isLoading, setIsLoading] = useState(false);
  const [splash, setSplash] = useState(true);
  const [isCashedOut, setIsCashedOut] = useState(false);

  const [gameConfig, setGameConfig] = useState(
    JSON.parse(localStorage.getItem("game-config"))
  );

  const [userBetHistory, setUserBetHistory] = useState(
    JSON.parse(localStorage.getItem("user-history"))
  );

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  let minBetValue = Number(gameConfig?.min_bid_amount);
  let maxBetValue = Number(gameConfig?.max_bid_amount);

  let minCashoutValue = Number(gameConfig?.min_cashout);
  let maxCashoutValue = Number(gameConfig?.max_cashout);

  let incrementDecrementBy = 0.5;

  const handleBet = async () => {
    const bidValue = {
      bid_amount: betValue,
      cashout: cashoutValue,
      game_id: localStorage.getItem("gameId"),
    };
    if (cashoutValue === 0) {
      bidValue.is_auto_cashout = true;
    }

    if (betValue === 0) {
      toast.error("Bet amount cannot be 0");
    } else if (betValue > balance?.amount) {
      toast.error("Insufficient balance");
    } else if (cashoutValue > 0 && cashoutValue < 1) {
      toast.error(`Invalid Auto Cashout (minimum ${minCashoutValue} x)`);
      setCashoutValue(minCashoutValue.toFixed(2));
    } else {
      bid({ data: bidValue }).then((res) => {
        if (res?.data?.status === "SUCCESS") {
          setIsPlaying(true);
          setIsLoading(true);
        }
      });
    }
  };
  const handleBetValue = (amt, checkValidation, balAmt) => {
    if (!checkValidation && amt > balAmt) {
      setBetValue(balAmt < maxBetValue ? balAmt : maxBetValue);
    } else if (amt > maxBetValue) {
      toast.error(`Maximum bet amount allowed is ${maxBetValue} ${unit}`);
      setBetValue(maxBetValue);
    } else if (!checkValidation && amt <= minBetValue) {
      setBetValue(minBetValue);
    } else if (!checkValidation) {
      setBetValue(Number(amt).toFixed(2));
    } else {
      setBetValue(amt);
    }
  };

  const handleOnBlur = (e) => {
    const { value } = e.target;
    if (!value || Number(value) < minBetValue) {
      toast.error(`Minimum bet amount allowed is ${minBetValue} ${unit}`);
      setBetValue(minBetValue);
    }
  };

  const handleCashOut = async () => {
    const cashoutValue = {
      bid_amount: betValue,
      cashout: currentOdd,
      game_id: localStorage.getItem("gameId"),
    };

    cashout({ data: cashoutValue }).then((res) => {
      if (res?.data?.status === "SUCCESS") {
        const winValue = betValue * currentOdd - betValue;
        toast.success(`You won ${winValue?.toFixed(2)} ${unit} `);
        setIsPlaying(false);

        setIsCashedOut(true);
      }
    });

    gameStartSound.fade(0, 0.6, 1500);

    const winAudios = [winMusic1, winMusic2, winMusic3];
    const randomIndex = Math.floor(Math.random() * winAudios.length);

    !isMuted && winAudios[randomIndex]();
  };

  const fetchBetHistory = () => {
    getUserBetHistory({ page: 1, pageSize: 10 }).then((res) => {
      setUserBetHistory(res.data);
    });
  };

  const toggleMute = () => {
    setIsMuted(!isMuted);

    stopLoseGameMusic();
    stopGameStartMusic();
    stopWinMusic1();
    stopWinMusic2();
    stopWinMusic3();
  };

  useEffect(() => {
    if (id) {
      login({ identifier: id }).then((res) => {
        setUserData(res.data);
      });
    }
    if (user) {
      localStorage.setItem("user", user);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("userData", JSON.stringify(userData));
    if (userData)
      getBalance().then((res) => {
        setBalance(res.data);
      });
  }, [userData]);

  useEffect(() => {
    socket.on("gamestate", (data) => {
      if (data) {
        setGameStarted(data.isStarted);
        setGameOver(data.isCrashed);
      }
    });
    socket.on("user_bids", (data) => {
      if (data) {
        setUserBidData(data.user_bid_data);
      }
    });
  }, []);

  useEffect(() => {
    if (!gameStarted && gameOver) {
      setIsLoading(true);
    }
    if (!gameStarted && !gameOver) {
      setIsLoading(false);
    }
    if (gameStarted) {
      setIsLoading(false);
      setIsCashedOut(false);
    }
    if (gameOver) {
      setIsPlaying(false);
      !isMuted && loseGameMusic();

      if (userData) fetchBetHistory();
    }
  }, [gameStarted, gameOver]);

  useEffect(() => {
    setTimeout(() => {
      setSplash(false);
    }, 3000);
  }, []);

  useEffect(() => {
    getGameConfig().then((res) => {
      setGameConfig(res.data);
    });
  }, []);

  useEffect(() => {
    if (userData) fetchBetHistory();
  }, [userData]);

  useEffect(() => {
    if (gameStarted && !isMuted && !splash) {
      gameStartMusic();
    } else {
      stopGameStartMusic();
    }
    return () => {
      stopGameStartMusic();
    };
  }, [gameStarted, isMuted, splash]);

  if (splash) {
    return <RektGameSplash />;
  }

  return (
    <>
      <Header balance={balance} showHistory={handleShow} />
      <Layout>
        <div className="game-page">
          <div className="container">
            <div className="game-body">
              <button className="mute-btn" onClick={toggleMute}>
                {isMuted ? <MdMusicOff size={30} /> : <MdMusicNote size={30} />}
              </button>

              <div className="game-bet-box ">
                <div className="target-tabs-data">
                  <div className="crash-tab-manual">
                    <ManualField
                      isPlaying={isPlaying}
                      setIsPlaying={setIsPlaying}
                      betValue={betValue}
                      setBetValue={setBetValue}
                      cashoutValue={cashoutValue}
                      setCashoutValue={setCashoutValue}
                      handleBet={handleBet}
                      gameStarted={gameStarted}
                      handleCashOut={handleCashOut}
                      gameOver={gameOver}
                      handleBetValue={handleBetValue}
                      handleOnBlur={handleOnBlur}
                      testMode={testMode}
                      isLoading={isLoading}
                      balance={balance}
                      minBetValue={minBetValue}
                      maxBetValue={maxBetValue}
                      minCashoutValue={minCashoutValue}
                      maxCashoutValue={maxCashoutValue}
                      incrementDecrementBy={incrementDecrementBy}
                    />
                  </div>

                  <BetContent
                    cashOutdata={userBidData}
                    currentOdd={currentOdd}
                    betValue={betValue}
                    gameOver={gameOver}
                    gameStarted={gameStarted}
                    isCashedOut={isCashedOut}
                    handleShowHistory={handleShow}
                    id={id}
                  />

                  <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                      <Modal.Title>Bet History</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <BetHistory
                        betHistory={userBetHistory}
                        setUserBetHistory={setUserBetHistory}
                      />
                    </Modal.Body>
                  </Modal>
                </div>
              </div>
              <div className="game-screen">
                <Chart
                  betValue={betValue}
                  currentOdd={currentOdd}
                  setCurrentOdd={setCurrentOdd}
                  setBalance={setBalance}
                  setIsPlaying={setIsPlaying}
                  isPlaying={isPlaying}
                  cashoutValue={cashoutValue}
                  setIsCashedOut={setIsCashedOut}
                  isMuted={isMuted}
                  stopGameStartMusic={stopGameStartMusic}
                  winMusic1={winMusic1}
                  winMusic2={winMusic2}
                  winMusic3={winMusic3}
                  gameStartSound={gameStartSound}
                />
                {isExploding && (
                  <div className="crash-explode">
                    <img src={explode} alt="" />
                    <h4>REKT</h4>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};
export default CrashGame;
